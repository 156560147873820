.image-gallery-image-item {
  /* width: 100vw; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition-duration: .4s;
}

.image-gallery-image-item.novisible {
  translate: 100%;
  display: none;
}

.image-gallery-image-item.active-image {
  translate: 0;
  display: block;
}